import React from "react"

import SEO from "../components/seo"
import Price from "../components/price"
import styles from "./tickets.module.css"
import Img from "gatsby-image"
import { graphql } from 'gatsby'

const TicketPage = ({data}) => {
  return (<div className="content">
    <SEO title="Tickets" />
    <h1>Tickets</h1>
    {/* <p className="short">
      Aktuell können keine Ticket gekauft werden.
    </p> */}
    <p className={"short"}>
      Vorverkauf im Frachtraum
    </p>
    <p className={styles.topWrapper + " long"}>
      Komm vorbei in den <a href="https://goo.gl/maps/mZQZQeh9n4mPhHV56" target="_blank">FrachtRaum, an der Seestrasse 14 in Thun</a> und zieh dir die authentischen Holz-Tickets oder kauf sie direkt <a target="_blank" href="https://eventfrog.ch/de/p/gruppen/max-cuap-6633279622748337060.html">online</a>.
      <Img
        className={styles.ticket}
        fixed={data.file.childImageSharp.fixed}
        alt=""
      />
    </p>
    <a className={styles.link} target="_blank" href="https://eventfrog.ch/de/p/gruppen/max-cuap-6633279622748337060.html">→ Tickets online kaufen</a>
    <h2>Unsere Tickets und Packages</h2>
    <Price title="Thun" amount="120" subtitle="JUST DO IT" target="Jedermann*Frau" slogan="Ich bin voll dabei.">
      → 1 Ticket<br />
      → Nachtessen inkl. Apéro, Dessert und alkoholfreie Getränke
    </Price>
    <Price title="KAPSTADT" amount="140" subtitle="SUPPORT ME, BABY" target="Family & Friends" slogan="Ich unterstütze gerne ein cooles Konzept - und finde die Organisatoren geili Siechä.">
      → 1 Ticket <br />
      → Nachtessen inkl. Apéro, Dessert und alkoholfreie Getränke <br />
      → Eine Umarmung
      von einem OK-Mitglied
      deiner Wahl
    </Price>
    <Price title="WOODSTOCK" amount="300" subtitle="THE GROUPIE PACKAGE" target="Konzertliebhaber" slogan="Ich bin ein Groupie und steh dazu.">
      → Drei Tickets für alle drei Konzerte
    </Price>
    <Price title="NEW ORLEANS" amount="200" subtitle="THE CONNOISSEUR PACKAGE" target="Geniesser und Freunde des guten Geschmacks" slogan="Ich will „ä hiubä Hinech u chli sy“.">
      → 1 Ticket<br />
      → Nachtessen inkl. Apéro, Dessert und alkoholfreie Getränke<br />
      → Einen reservierten Sitzplatz zum Essen<br />
      → Exklusives Pasta Give Away
    </Price>
    <Price title="FIJI" amount="500" subtitle="ALL IN" target="Klotzer statt Kleckerer" slogan="Ich steht auf Luxus - und ja, ich trinke gerne das eine oder andere Gläschen.">
      → Reservierte Sitzplätze zum Essen<br />
      → Butler am Tisch<br />
      → 4-Gang-Menü<br />
      → Alle Getränke inklusive<br />
      → Hochwertiges Give Away<br />
    </Price>
  </div>)
}

export const query = graphql`
  query {
    file(relativePath: { eq: "ticket.png" }) {
      childImageSharp {
        fixed(width: 200, height: 154) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default TicketPage