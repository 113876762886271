import React from "react"
import styles from "./price.module.css"

class Price extends React.Component {
    render() {
        return <div className={styles.price}>
            <h2 className={styles.header}>{this.props.title}</h2>
            <span className={styles.amount}>{this.props.amount}CHF</span>
            <span className={styles.subtitle}>"{this.props.subtitle}"</span>
            <span className={styles.subheading}>GEEIGNET FÜR?</span>
            <span>{this.props.target}</span>
            <span className={styles.subheading}>WAS?</span>
            <span>{this.props.children}</span>
            <span className={styles.subheading}>MOTTO:</span>
            <span>{this.props.slogan}</span>
        </div>
    }
}

export default Price
